<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >Email Template Editor</div>
                <div v-if="curItem">template: {{ curItem.name }}</div>
              </div>
            </div>
        </div>
      </div>
      
      <b-tabs type="is-toggle" class="m1rem" v-model="activeTab">
        
        <b-tab-item label="Start">     
          <b-button size="is-small" @click="newTemplate">New</b-button>
               
          <b-field>
            <b-select multiple native-size="10" v-model="selectedOptions" size="is-medium">
              <option v-for="(item, index) in loadItems" v-bind:key="`loadItems_${index}`" @dblclick="loadTemplate(item)">
                {{ item.name }}
              </option>
            </b-select>
          </b-field>
          
        </b-tab-item>
        
        
        
        <b-tab-item v-if="curItem" label="Main Template">          
          <b-button size="is-small" @click="saveTemplate">Save</b-button>
          
          <b-field label="subject">
            <b-input v-model="frmData.mainTemplate.subject"></b-input>
          </b-field>
          
          <b-field label="pre-header">
            <b-input v-model="frmData.mainTemplate.text"></b-input>
          </b-field>
          
          <b-field label="Main Mail Template">
            <b-input maxlength="2000000" type="textarea" custom-class="textareaHeightMainTemplate" v-model="frmData.mainTemplate.body"></b-input>
          </b-field>
        </b-tab-item>
        
        
        <b-tab-item v-if="curItem" label="Parts">
          <b-button size="is-small" @click="saveTemplate">Save</b-button>
          
          <div v-for="(part, index) in frmData.templateParts" v-bind:key="`templateParts_${index}`">
            <b-field :label="`${part.name} -> ${part.code}`">
              <b-input maxlength="200000" type="textarea" custom-class="textareaHeightPartTemplate" v-model="part.body"></b-input>
            </b-field>
          </div>
          
        </b-tab-item>
        
        
        
        
        <b-tab-item v-if="curItem" label="Users">
          <b-button size="is-small" @click="saveTemplate">Save</b-button>
          <div>
            <input type="file" @change="uploadFile" ref="file">
            <b-button v-if="userFile" @click="submitFile">Upload!</b-button>
          </div>
          
          <div class="is-12 is-gapless is-centered is-multiline m2rem" v-if="frmData.usersData.data.length > 0">
            <h1 class="column is-12">fields: {{ frmData.usersData.data.length }}</h1>    
            
            <div class="columns is-multiline">    
              <label class="label">Admin</label>
              <b-field>
                <b-select multiple size="is-small" native-size="2" v-model="testEmailList">
                    <option value="csmaltha@gmail.com">chris: csmaltha@gmail.com</option>
                    <option value="k.maltha@waarneemapp.nl">kasper: k.maltha@waarneemapp.nl</option>
                    <option value="k.maltha@propersona.nl">kasper: k.maltha@propersona.nl</option>
                </b-select>
              </b-field>
            </div>
            
            <div class="columns is-multiline">    
              <div class="column is-2" v-for="(status, colName) in talbeColumns" v-bind:key="`${colName}_index`">
                <b-button @click="toggleShowStatus(status, colName)" :type="status === true ? 'is-success' : ''">
                  {{ colName.split(`_`).join(` `) }}
                </b-button>
              </div>
            </div>
          
            <b-table
                
                :data="frmData.usersData.data"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :loading="tableIsLoading"
                :mobile-cards="true">

                <b-table-column :visible="talbeColumns['full name of user']" field="full name of user" label="full name" v-slot="props" sortable centered searchable>
                    {{ props.row['full name of user'] }}
                </b-table-column>
                <b-table-column :visible="talbeColumns['first name']" field="first name" label="first name" v-slot="props" sortable centered searchable>
                    {{ props.row['first name'] }}
                </b-table-column>
                <b-table-column :visible="talbeColumns['insertion']" field="insertion" label="insertion" v-slot="props" sortable centered searchable>
                    {{ props.row['insertion'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['lastname']" field="lastname" label="lastname" v-slot="props" sortable centered searchable>
                    {{ props.row['lastname'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['wa_email']" field="wa_email" label="wa email" v-slot="props" sortable centered searchable>
                    {{ props.row['wa_email'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['intershift_email']" field="intershift_email" label="intershift email" v-slot="props" sortable centered searchable>
                    {{ props.row['intershift_email'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['wa big_number']" field="wa big_number" label="wa big number" v-slot="props" sortable centered searchable>
                    {{ props.row['wa big_number'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['intershift - big_number']" field="intershift - big_number" label="intershift big_number" v-slot="props" sortable centered searchable>
                    {{ props.row['intershift - big_number'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['email match']" field="email match" label="email match" v-slot="props" sortable centered searchable>
                    {{ props.row['email match'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['email mismatch']" field="email mismatch" label="email mismatch" v-slot="props" sortable centered searchable>
                    {{ props.row['email mismatch'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['bignumber mismatch']" field="bignumber mismatch" label="bignumber mismatch" v-slot="props" sortable centered searchable>
                    {{ props.row['bignumber mismatch'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['voornaam']" field="voornaam" label="voornaam" v-slot="props" sortable centered searchable>
                    {{ props.row['voornaam'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['voorletters']" field="voorletters" label="voorletters" v-slot="props" sortable centered searchable>
                    {{ props.row['voorletters'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['tussenvoegsel']" field="tussenvoegsel" label="tussenvoegsel" v-slot="props" sortable centered searchable>
                    {{ props.row['tussenvoegsel'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['achternaam']" field="achternaam" label="achternaam" v-slot="props" sortable centered searchable>
                    {{ props.row['achternaam'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['mobiel telefoon']" field="mobiel telefoon" label="mobiel telefoon" v-slot="props" sortable centered searchable>
                    {{ props.row['mobiel telefoon'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['rol']" field="rol" label="rol" v-slot="props" sortable centered searchable>
                    {{ props.row['rol'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['url']" field="url" label="url" v-slot="props" sortable centered searchable>
                    <a :href="`${props.row['url']}`" target="_blank">{{ props.row['url'] }}</a>
                </b-table-column>
                
                
                <b-table-column  :visible="talbeColumns['p1_role_pa_vs']" field="p1_role_pa_vs" label="p1 role pa vs" v-slot="props" sortable centered searchable>
                    {{ props.row['p1_role_pa_vs'] }}
                </b-table-column>
                
                <b-table-column  :visible="talbeColumns['p1_role_praktijkhouder']" field="p1_role_praktijkhouder" label="p1 role praktijkhouder" v-slot="props" sortable centered searchable>
                    {{ props.row['p1_role_praktijkhouder'] }}
                </b-table-column>                
                <b-table-column  :visible="talbeColumns['p1_role_regulier']" field="p1_role_regulier" label="p1 role regulier" v-slot="props" sortable centered searchable>
                    {{ props.row['p1_role_regulier'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['p1_role_vip_vaste_hidha']" field="p1_role_vip_vaste_hidha" label="p1 role vip vaste hidha" v-slot="props" sortable centered searchable>
                    {{ props.row['p1_role_vip_vaste_hidha'] }}
                </b-table-column>                
                <b-table-column  :visible="talbeColumns['p2_scenario1_account_match']" field="p2_scenario1_account_match" label="p2 scenario1 account match" v-slot="props" sortable centered searchable>
                    {{ props.row['p2_scenario1_account_match'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['p2_scenario2_no_big_email_match']" field="p2_scenario2_no_big_email_match" label="p2 scenario2 no big email match" v-slot="props" sortable centered searchable>
                    {{ props.row['p2_scenario2_no_big_email_match'] }}
                </b-table-column>                
                <b-table-column  :visible="talbeColumns['p2_scenario3_bigfound_no_email_match']" field="p2_scenario3_bigfound_no_email_match" label="p2 scenario3 bigfound no email match" v-slot="props" sortable centered searchable>
                    {{ props.row['p2_scenario3_bigfound_no_email_match'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['p2_scenario4_no_account_found']" field="p2_scenario4_no_account_found" label="p2 scenario4 no account found" v-slot="props" sortable centered searchable>
                    {{ props.row['p2_scenario4_no_account_found'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['p3_scenario1_2_3']" field="p3_scenario1_2_3" label="p3 scenario1,2,3" v-slot="props" sortable centered searchable>
                    {{ props.row['p3_scenario1_2_3'] }}
                </b-table-column>                
                <b-table-column  :visible="talbeColumns['p3_scenario4']" field="p3_scenario4" label="p3 scenario4" v-slot="props" sortable centered searchable>
                    {{ props.row['p3_scenario4'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['scenario']" field="scenario" label="scenario" v-slot="props" sortable centered searchable>
                    {{ props.row['scenario'] }}
                </b-table-column>
                <b-table-column  :visible="talbeColumns['errors']" field="errors" label="errors" v-slot="props" sortable centered searchable>
                    {{ props.row['errors'] ? props.row['errors'].join(`- `) : '' }}
                </b-table-column>
                
                <b-table-column label="test" v-slot="props" sortable centered searchable>
                    <b-button size="is-small" @click="sendTestEmail(props)">Test</b-button>
                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>

            </b-table>
          </div>
          
          <b-button v-if="frmData.usersData.data.length" size="is-large" @click="verstuurEmailBatch()">verstuurEmailBatch</b-button>
          
        </b-tab-item>
      </b-tabs>
        
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          companyId: 0,
          
          testEmailList: ["csmaltha@gmail.com"],
          
          loadItems: [],
          curItem: null,
          
          frmData: {
            templateName: '',
            filename: '',
            mainTemplate: {
              subject: '',
              text: '',
              body: ''
            },
            templateParts: [],
            usersData: {
              excelFilename: null,
              data: []
            }
            
          },
          
          tableIsLoading: false,
          
          talbeColumns: {
              "full name of user": false,
              "first name": false,
              "insertion": false,
              "lastname": false,
              "wa_email": true,
              "intershift_email": true,
              "wa big_number": true,
              "intershift - big_number": true,
              "email match": false,
              "email mismatch": false,
              "bignumber mismatch": false,
              "voornaam": false,
              "voorletters": false,
              "tussenvoegsel": false,
              "achternaam": false,
              "mobiel telefoon": false,
              "rol": true,
              "url": false,
              
              "p1_role_pa_vs": false,
              "p1_role_praktijkhouder": false,
              "p1_role_regulier": false,
              "p1_role_vip_vaste_hidha": false,
              "p2_scenario1_account_match": false,
              "p2_scenario2_no_big_email_match": false,
              "p2_scenario3_bigfound_no_email_match": false,
              "p2_scenario4_no_account_found": false,
              "p3_scenario1_2_3": false,
              "p3_scenario4": false,
              "scenario": true,
              "errors": true,
          },
          
          
          selectedOptions: [],          
          activeTab: 0,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
          
          userFile: null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        this.getData()
      },
      methods: {
        async getData() {
          this.ShowPageLoading(5000)
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/list`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get email list data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.loadItems = response.data.payload.templateList
          this.HidePageLoading()
        },
        
        
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
        
        
        async newTemplate() {
          console.info(`newTemplate:`)
          const _this = this;      
          this.$buefy.dialog.confirm({
              message: `create new template ?`,
              onConfirm: async (value) => {
                _this.newTemplateConfirm()
              }
          })          
        },
        async newTemplateConfirm() {
          this.ShowPageLoading(5000)
          this.curItem = null
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/new`);
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get template data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          
          await this.getData();
          
          // console.info(`response:`, response)
          this.frmData = response.data.payload.template
          this.curItem = this.loadItems.find((item) => { return item.filename = this.frmData.filename })
          console.info(`this.curItem:`, this.curItem)
          this.HidePageLoading()
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        
        
        async loadTemplate(item) {
          console.info(`loadTemplate:`, item)
          this.loadTemplateConfirm(item)
        },
        async loadTemplateConfirm(item) {
          this.ShowPageLoading(5000)
          this.curItem = null
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/load`, {
            filename: item.filename
          });
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get template data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.frmData = response.data.payload.template
          this.curItem = item
          this.HidePageLoading()
        },
        
        async saveTemplate() {
          console.info(`this.curItem:`, this.curItem)
          if (!this.curItem) {
            return this.$buefy.dialog.alert({ title: 'Error', message: 'no curItem template found', type: 'is-danger' })
          }
          
          this.ShowPageLoading(5000)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/save`, {
            filename: this.curItem.filename,
            frmData: this.frmData
          });
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to save template data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`Saved response:`, response)          
          this.HidePageLoading()
        },
        
        toggleShowStatus(status, index){
          this.talbeColumns[index] = !status
        },
        
        async verstuurEmailBatch(){
          const _this = this;      
          this.$buefy.dialog.confirm({
              message: `Weet je zeker dat je de email batch wilt versturen ?`,
              onConfirm: async (value) => {
                _this.verstuurEmailBatchConfirm()
              }
          })
        },
        async verstuurEmailBatchConfirm(){
          console.info(`sendTestEmail props:`)
          if (this.frmData.usersData.data.length <=0) {
            return this.$buefy.dialog.alert({ title: 'Error', message: 'no users found', type: 'is-danger' })
          } 
          
          //save template before sending!
          await this.saveTemplate() 
          
          this.ShowPageLoading(5000)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/sendEmailToAll`, {
            frmData: this.frmData,
          });
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to send batch email', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`Saved response:`, response)          
          this.HidePageLoading()   
          
          this.curItem = null          
          this.$buefy.dialog.alert({ title: 'Error', message: 'email batch sending now!', type: 'is-danger' })
        },
        
        
        async sendTestEmail(props){
          console.info(`sendTestEmail props:`, props)
          if (this.testEmailList.length <=0) {
            return this.$buefy.dialog.alert({ title: 'Error', message: 'no admin for test email selected', type: 'is-danger' })
          }          
          
          this.ShowPageLoading(5000)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/sendTestEmail`, {
            frmData: this.frmData,
            testUserProps: props.row,
            testEmailList: this.testEmailList
          });
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to send test email', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`Saved response:`, response)          
          this.HidePageLoading()
        },
        
        uploadFile() {
          this.userFile = this.$refs.file.files[0];
        },
        async submitFile() {
          if (!this.userFile) {
            return this.$buefy.dialog.alert({ title: 'Error', message: 'invalid file', type: 'is-danger' })
          }
          console.info(`submitting file:`, this.userFile)          
          this.ShowPageLoading(5000)          
          
          const formData = new FormData();
          formData.append('file', this.userFile);
          const headers = { 'Content-Type': 'multipart/form-data' };
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/emailTemplate/uploadUsers`, formData, { headers })
          console.info(`response:`, response)
          
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to upload template data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.frmData.usersData = response.data.payload
          this.HidePageLoading()
        }
      }
    }
</script>


<style>

  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .textareaHeightMainTemplate{
    /* background-color: red; */
    font-size: 12px;
    height: 800px;
    max-height: 800px!important;
  }
  .textareaHeightPartTemplate{
    /* background-color: red; */
    font-size: 12px;
    height: 200px;
    max-height: 200px!important;
  }
</style>